import React, { Fragment, useContext } from "react"
import { faBirthdayCake, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { Link } from "gatsby"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import AddressContainer from "./Addresses/AddressContainer"

import { AppContext } from "../../context/AppContext"

const InfoIcon = ({ children, icon, className }) => (
  <p
    className={classNames(
      "is-size-6 is-flex is-align-items-center",
      className || ""
    )}
  >
    <span className="icon mr-1">
      <FontAwesomeIcon icon={icon} />
    </span>
    <span>{children}</span>
  </p>
)

const ProfileInfo = ({ addresses, setAddresses, userData, location, user }) => {
  const { dispatch } = useContext(AppContext)

  const handleDeleteCallback = (addressDocument) => {
    setAddresses(addressDocument)
  }

  let patientBirthday = "N/A"
  if (userData?.birthday) {
    let { month, date, year } = userData?.birthday
    patientBirthday = `${month} ${date}, ${year}`
  }

  return (
    <Fragment>
      <Section
        title="Personal Details"
        addOns={{
          right: <EditDetailsButton route="/profile/edit" />,
        }}
        isRequired
        isSectionRequired
      >
        <h4 className="has-text-black">
          {userData?.firstName} {userData?.lastName}
        </h4>
        <InfoIcon icon={faPhone}>
          Mobile Number: {userData?.mobileNumber}
        </InfoIcon>
        <InfoIcon icon={faBirthdayCake}>Birthday: {patientBirthday}</InfoIcon>
      </Section>
      <Section title="Addresses" isRequired isSectionRequired>
        {addresses.map((address, index) => {
          return (
            <AddressContainer
              address={address}
              index={index}
              setAddresses={setAddresses}
              handleDeleteCallback={handleDeleteCallback}
            />
          )
        })}
        <Link
          to="/profile/add-address"
          state={{ nextRoute: "/profile", backRoute: "/profile" }}
        >
          + Add new address
        </Link>
      </Section>
    </Fragment>
  )
}

export default ProfileInfo
